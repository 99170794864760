/* eslint-disable */
import moment from "moment";
import customMap from "lodash/map";
import customFilter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import customToLower from "lodash/toLower";
import customIndexOf from "lodash/indexOf";
import psl from "psl";
import axiosInstance from "./Shared/Interceptor";
import EnglishLiterals from "./EnglishLiterals.json";
import JapaneseLiterals from "./JapaneseLiterals.json";
import ChineseLiterals from "./ChineseLiterals.json";

// import i18n from "i18next";

//export const BASEURL = "https://clientcockpitapidev.accenture.com/api/";
//export const BASEURL = "https://clientcockpitmasterapiuat.accenture.com/api/"
export const BASEURL = process.env.REACT_APP_ADAL_CONFIG_BASEURL;
export const SVMBASEURL = process.env.REACT_APP_ADAL_CONFIG_SVM_URL;
export const SVM_COMMON_URL = process.env.REACT_APP_COMMON_URL;
export const ASSETBASEURL = process.env.REACT_APP_ADAL_CONFIG_ASSETBASEURL;
export const ClientLogoURL = process.env.REACT_APP_ADAL_CONFIG_ClientLogo_URL;
export const OPRIA_URL = process.env.REACT_APP_OPRIA_URL;
// export const OPRIA_URL = "https://design.opria.accenture.com/"
export const PAS_PROXY_BASE_ROUTE = process.env.REACT_APP_PAS_PROXY_BASE_ROUTE;
export const StaticClientLogoURL =
  "https://synops.accenture.com//static/media/client-0.26c2f20e.svg";
// export const BASEURL = "https://clientcockpitapi.accenture.com/api/" // prod
// export const BASEURL = "https://clientcockpitapiuat.accenture.com/Clientcockpit_API/api/"
// export const BASEURL = "https://clientcockpitapiuat.accenture.com/api/";
// export const SVMBASEURL =
//   "https://clientcockpitapiuat.accenture.com/CommonApi/api/";
// export const SVM_COMMON_URL =
//   "https://operations360valuemeter-test.accenture.com/api/";
export const USERNAME = capitalizeName(sessionStorage.getItem("userName"));
export const AUTHCOOKIE = getCookie("authToken");
//export const ADALTENANT = "f3211d0e-125b-42c3-86db-322b19a65a22";
// export const ADALTENANT = "e0793d39-0939-496d-b129-198edd916feb";//prod
// export const ADALCLIENTID = "43e3a0b0-b09e-49f3-8f50-865d1dad7875";// UAT
//export const ADALCLIENTID = "43e3a0b0-b09e-49f3-8f50-865d1dad7875";
// export const ADALREDIRECTURL = "https://clientcockpituat.accenture.com";//UAT
// export const ADALPOSTREDIRECTURL = "https://clientcockpituat.accenture.com/logout"; // UAT

// export const ADALAPI = "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/authorize";
// export const ADALAPI = "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/oauth2/authorize"; //prod

// export const ADALCLIENTID = "26390f73-63f5-4425-a120-bb2d2659a9d4";//master dev
// export const ADALREDIRECTURL = "https://clientcockpitmasterdev.accenture.com";//master dev
// export const ADALPOSTREDIRECTURL = "https://clientcockpitmasterdev.accenture.com/logout";//master dev

// export const ADALCLIENTID = "40c9e650-5005-4019-b96b-b685850ec403";
// export const ADALREDIRECTURL = "https://clientcockpitdev.accenture.com";
// export const ADALPOSTREDIRECTURL = "https://clientcockpitdev.accenture.com/logout";

// export const ADALCLIENTID = "88d41422-0c15-442b-8227-18b0a25973b8"; // prod
// export const ADALREDIRECTURL = "https://clientcockpit.accenture.com";// prod
// export const ADALPOSTREDIRECTURL = "https://clientcockpit.accenture.com/logout";// prod

export const BATDateFormat = "MMM YYYY"; // date format as ex: Apr 2019
export const FORMAT_DATE = "DD MMM YYYY";
export const BATAllowedMinDateMoment = moment("2019-09-01 00:00Z");

export const maturityPhaseOrder = [
  {
    phaseId: 1,
    phase: "Foundation",
  },
  {
    phaseId: 2,
    phase: "Automated",
  },
  {
    phaseId: 3,
    phase: "Insights Driven",
  },
  {
    phaseId: 4,
    phase: "Intelligent",
  },
];
export const initiativeStatusOrder = [
  {
    statusId: 0,
    status: "All",
  },
  {
    statusId: 3,
    status: "Delivered",
  },
  {
    statusId: 2,
    status: "In Flight",
  },
  {
    statusId: 1,
    status: "In Concept",
  },
];
export const iojOperations = [
  {
    operationId: 1,
    operation: "Stable Operations",
  },
  {
    operationId: 2,
    operation: "Efficient Operations",
  },
  {
    operationId: 3,
    operation: "Predictive Operations",
  },
  {
    operationId: 4,
    operation: "Future Ready Operations",
  },
];

export const TRACKING_MODULES = {
  EXPIRED_SESSION: 0,
  OVERVIEW: 1,
  BUSINESS_OUTCOME: 2,
  FUNCTIONS_IN_DELIVERY: 3,
  INTELLIGENT_OPERATIONS_JOURNEY: 4,
  LOGIN: 5,
  FUNCTIONS_IN_DELIVERY_INTELLIGENT_OPERATIONS_JOURNEY: 7,
  MOBILE_COCKPIT: 8,
  FEEDBACK: 9,
  SETTINGS: 10,
  CONFIGURATION: 11,
  REFERENCES: 12,
  CONFIGURATION_SIGN_OFF: 13,
  HIGHLIGHTS: 14,
  OVERVIEW: 15,
  CLIENT_STORY: 16,
  BUSINESS_OUTCOME: 17,
  REFERENCES: 18,
  FOUNDATION: 19,
  AUTOMATED: 20,
  INSIGHTS_DRIVEN: 21,
  INTELLIGENT: 22,
  CONFIGURATION_OVERALL_PROGRESS_STATUS: 23,
  CONFIGURATION_CLIENT_ONBOARDING_OFFERING_TAXONOMY: 24,
  CONFIGURATION_CLIENT_ONBOARDING_METRIC_ONBOARDING: 25,
  CONFIGURATION_FUNCTIONS_IN_DELIVERY_TEAM: 26,
  CONFIGURATION_FUNCTIONS_IN_DELIVERY_NOTIFICATIONS: 27,
  CONFIGURATION_FUNCTIONS_IN_DELIVERY_IOJ: 28,
  OVERVIEW_KEY_FACTS: 29,
  OVERVIEW_HIGHLIGHTS: 30,
  OVERVIEW_FEATURED_APP: 31,
  OVERVIEW_CLIENT_STORY: 32,
  CONFIGURATION_APPROVERS: 33,
  STAGES_OF_CLIENTS: 34,
  MOMENTS_TEAMS: 35,
  REPOSITORY: 36,
  APPEARS_ON_BO_PAGE: 37,
};

export const TRACKING_MODULES_ROUTE = {
  "/Overview": "OVERVIEW",
  "/FunctionsInDelivery": "FUNCTIONS_IN_DELIVERY",
  "/FunctionsInDelivery/BusinessOutcomes/": "BUSINESS_OUTCOME",
  "/FunctionsInDelivery/IntelligentOperationsJourney":
    "INTELLIGENT_OPERATIONS_JOURNEY",
};

export const TRACKING_MODULE_EVENTS = {
  GUIDED_TOUR_BUTTON: "Guided Tour button at header",
  THEME_SELECTION_BUTTON: "Theme Selection button inside settings tab",
  DEFAULT_LANDING_PAGE_INSIDE_TAB: "Default Landing page inside settings tab",
  APPLY_BUTTON_INSIDE_TAB: "Apply button inside settings tab",
  NOTIFICATION_HEADER_BESIDES_SETTING_BUTTON:
    "Notifications at header besides Settings button",
  PAGE_WISE_NOTIFICATIONS: "Page wise notifications",
  _360_DEGREE_VALUE_METER: "360-degree value meter",
  DIMENSION_VIEW: "Dimensions in List and Grid view",
  KEY_UPDATES: "Key Updates",
  PROJECT_DETAILS_BUTTON: "Project Details button",
  VIEW_SUMMARY_IN_FEATURED_APPS: "View Summary link in Featured Apps Section",
  LINK_IN_FEATURED_APPS: "Link in Featured Apps",
  VIEW_CASE_STUDY: "View Case Study",
  SCROLL_BUTTONS_INSIDE_CASE_STUDY: "Scroll buttons inside Case study",
  TEAM_STRUCTURE: "Team Structure",
  NOTIFICATION_BUTTON: "Notification button",
  OPEN_BUTTON: "Open Button",
  CRITICAL_OUTCOME_BUTTON: "Critical Outcome Arrow button",
  ALL_BO_CARDS: "All BO Cards",
  COMMENT_BUTTON_CARD: "Comment Button the Card",
  MULTIMETRIC_VIEW_BUTTON: "Multimetric view toggle button inside BO Card",
  COMMENT_BUTTON: "Comment Button",
  ANNOTATION: "Annotation",
  REPLY_BUTTON: "Reply Button on comment section",
  FEATURED_APPS_VIEW_SUMMARY: "Featured Apps View Summary",
  FEATURED_APPS_LINK: "Featured Apps Link",
  REFERENCES: "References",
  REFERENCES_IN_ALL_REFERENCES: "References in all References View",
  FOUNDATION_AUTOMATED_INSIGHTS_DRIVEN_INTELLIGENT:
    "Foundation, Automated, Insights driven, Intelligent",
  DRILL_DOWN_ON_INITIATIVE: "Drill down on any initiative",
  SUCCESS_STORY: "Success Story",
  INSIGHTS_BUTTON: "Insights Button in Metric Card",
};

export const IDLE_TIMEOUT = 30 * 60 * 1000;
export const BOUNCE_RATE_IDLE_TIMEOUT = 15 * 60 * 1000;

export function createRequestOptions(token) {
  var requestHeaders = new Headers();
  requestHeaders.append("Content", "application/json");
  requestHeaders.append("Authorization", "Bearer " + token);
  var requestOptions = {
    method: "GET",
    headers: requestHeaders,
  };
  return requestOptions;
}

export function getCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

export function getSessionData(name) {
  return sessionStorage.getItem(name);
}

export function capitalizeName(name) {
  if (name != null) return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  return "";
}

export function formatDate(momentObj, format = BATDateFormat) {
  return momentObj.format(format);
}

export function formattedmetricCardStartMonth(momentObj) {
  return formatDate(momentObj.subtract(17, "months"));
}

export function metricCardStartMonth(momentObj) {
  return momentObj.subtract(17, "months");
}

export function metricCardCalendarMinDate(momentObj) {
  return moment(momentObj)
    .add(3, "months")
    .toDate();
}

export function metricCardCalendarMaxDate(momentObj) {
  return moment(momentObj)
    .subtract(3, "months")
    .toDate();
}

export const PARAM_NAMES = {
  OFFERING: "offering",
  SUB_OFFERING: "subOffering",
  DIMENSION_NAME: "dimensionName",
  GEOGRAPHY: "geography",
  TYPE: "type"
}

export function createParamObject(ParamName, ParamValue) {
  return {
    ParamName,
    ParamValue,
    ParamType: "UserActivity",
  };
}

export function userTracker(moduleId, end = false, data = {}, onLogout, ...params) {
  // new Promise((resolve, reject) => {
  // Avoid Seding Login Tracker if login tracker is already sent
  const languageCode = sessionStorage.getItem("languageCode");

  if (
    ![TRACKING_MODULES.LOGIN, TRACKING_MODULES.EXPIRED_SESSION].includes(
      moduleId
    )
  ) {
    window.currentTrackingModule = moduleId;
  }

  let accessName = null;
  let accessValue = new Date();
  accessName = end ? "accessEndTime" : "accessStartTime";

  let isLandinPage = false;
  const route = window.location?.hash;

  if (route && route.includes("landingpage")) {
    isLandinPage = true;
  }

  const defaultParam = {
    ParamName: "Client",
    ParamValue: !isLandinPage ? sessionStorage.getItem("clientName") : "NA",
    ParamType: "UserActivity"
  }
  
  const payload = {
    ...data,
    // token: sessionStorage.getItem("adal.idtoken"),
    token: sessionStorage.getItem("accessToken"),
    clientId: !isLandinPage ? sessionStorage.getItem("clientId") : 0,
    userId: sessionStorage.getItem("userId"),
    moduleId,
    params:[defaultParam, ...params],
    source: "CCP",
    [accessName]: accessValue,
  };

  axiosInstance
    .post(
      `${SVMBASEURL}UserTracking/user-log?languageCode=${languageCode}`,
      payload
    )
    .then(() => {
      if (onLogout) {
        onLogout();
      }
      // resolve(res.data);
    })
    .catch(() => {
      // reject(err);
    });
  // });
}

export const createIdleTimer = () => {
  // clearIdleTimeout();
  // window.idleTimer = window.setTimeout(() => {
  //   userTracker(TRACKING_MODULES.EXPIRED_SESSION, true);
  //   window.idleTimer = null;
  // }, IDLE_TIMEOUT);
};

export const clearIdleTimeout = () => {
  // window.clearTimeout(window.idleTimer);
  // window.idleTimer = null;
  // var id = window.setTimeout(function() {}, 0);
  // while (id--) {
  //   window.clearTimeout(id);
  // }
};

export const level = {
  warn: "warn",
  error: "error",
  debug: "debug",
  info: "info",
  default: "log",
};

export const logger = (message, type = "log") => {
  if (process.env.NODE_ENV === undefined) {
    let tm = moment()
      .utc()
      .add(6, "hours")
      .format("DD MMM, YYYY h:m A");
    console[level[type]](`[${tm}]`, message, type);
  }
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "production"
  ) {
    // Can we used for Production logging solution
    // publishLog({ message: message, level: level[type.toLowerCase()] });
  }
};

export const LANG_LITERALS = {
  en: { translation: EnglishLiterals },
  ja: { translation: JapaneseLiterals },
  zh: { translation: ChineseLiterals },
};

export const MATURITY_PHASE = {
  FOUNDATION: 1,
  AUTOMATED: 2,
  INSIGHTS_DRIVEN: 3,
  INTELLIGENT: 4,
};

export const INITIATIVE_STATUS = {
  ALL: 0,
  IN_CONCEPT: 1,
  IN_FLIGHT: 2,
  DELIVERED: 3,
};
export const userTrackingUserBounce = () => {
  const payload = {
    source: "CCP",
    userId: sessionStorage.getItem("userId"),
    clientId: sessionStorage.getItem("clientId"),
    // moduleId: moudleId,
    // moduleEvent: moduleEvent,
  };

  axiosInstance.post(`${SVMBASEURL}UserTracking/add-bounce-user`, payload);
};

export const userTrackingModuleEvent = (moduleId, moduleEvent) => {
  if (moduleId && moduleEvent) {
    const payload = {
      source: "CCP",
      userId: sessionStorage.getItem("userId"),
      clientId: sessionStorage.getItem("clientId"),
      moduleId: moduleId,
      moduleEvent: moduleEvent,
    };

    axiosInstance.post(`${SVMBASEURL}UserTracking/event-track`, payload);
  }
};

export const getCurrentModuleId = (pathname) => {
  try {
    if (!pathname) throw new Error("Pathname not found");
    const module = TRACKING_MODULES_ROUTE[pathname];
    if (!module) throw new Error("No User Tracking Module Name");
    const moduleId = TRACKING_MODULES[module];
    if (!moduleId) throw new Error("No Tracking Module ID");
    return moduleId;
  } catch (err) {
    return null;
  }
};

export const noop = () => { };

export const loadScript = (src, scriptId, onSuccess = noop, onError = noop) => {
  let script = document.getElementById(scriptId);
  if (!script) {
    script = document.createElement("script");
    script.type = "application/javascript";
    script.src = src;
    script.id = scriptId;
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener("load", onSuccess);
    script.addEventListener("error", onError);
  }
  script.addEventListener("load", onSuccess);
  script.addEventListener("error", onError);
}


export const sanitizeHTML = function (str) {
  var temp = document.createElement('div');
  temp.textContent = str;
  return temp.innerHTML;
};

export const Domparser = function (str) {
  console.log(str);
  let parser = new DOMParser();
  let dom = parser.parseFromString(str, "text/html");
  console.log(dom.body, dom.body.innerHTML);
  return dom.body;
}

export const validateURL = (url) => {
  if (url.match("[^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)]$")) {
    return "__error";
  }
  else {
    return url;
  }

};
// export const validateURL = (url) => {
//   if (url.match("[^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)]$")) {
//     return "__error";
//   }
//   else {
//     return url;
//   }
// };

export const ToChunk = (Originalarray, size) => {
  const chunkedarray = [];
  let index = 0;
  while (index < Originalarray.length) {
    chunkedarray.push(Originalarray.slice(index, size + index));
    index += size;
  }
  return chunkedarray;
};

export const formattertwo = new Intl.NumberFormat('en', {
  useGrouping: true
});

// export const notDescriptionRegex = /[~`<>|[\]$^*+=!@#%&;:?'"{}]/;
// export const caseStudyNameRegex = /^[a-zA-Z0-9\s_()-]+$/;
// export const notificationNameRegex = /^[a-zA-Z0-9\s_()-]+$/;
// export const notificationDescriptionRegex = /^[a-zA-Z0-9\s_()<>/-]+$/;
// export const highlightFeatureNameRegex = /^[a-zA-Z0-9\s_()-]+$/;
// export const highlightDescriptionRegex = /^[a-zA-Z0-9\s_-]+$/;
// export const roleRegex = /^[a-z A-Z]+$/;
// export const keyFactRegex = /^[a-zA-Z0-9\s_()<>/-]+$/;
// export const feedbackSubjectRegex = /^[a-zA-Z0-9\s_()-]+$/;
// export const feedbackDescriptionRegex = /^[a-zA-Z0-9\s_()-]+$/;
// export const issueSubjectRegex = /^[a-zA-Z0-9\s_()-]+$/;
// export const issueDescriptionRegex = /^[a-zA-Z0-9\s_()-]+$/;
// export const surveyNameRegex = /^[a-z A-Z0-9\s]+$/;
// export const feedbackQuestionRegex = /^[a-z A-Z0-9?\s]+$/;
// export const optionsRegex = /^[a-z A-Z0-9\s]+$/;
// export const remarksRegex = /^[a-zA-Z0-9\s_()-]+$/;
export const commonRegex = /^[A-Za-z0-9!&%*$()<>:;',?\\/_\\\\@.#\t\s\n&-]*$/;

export const commonToolRegex = /[\s\\<>]/;
export const regexForToolURL = /^http[s]?\:\/\/[^\/\s]+(\/.*)?$/;
export const getWhitelistedURL = (data) => {
  return customMap(customFilter(data, {IsActive: true}), "Domain");
};
export const validateIsAllowedDomainFromURL = (urlStr, allowedDomainsArray) => {
  let isDomainAllowed = false;

  if (isEmpty(urlStr) || isEmpty(allowedDomainsArray)) {
    isDomainAllowed = true;
  } else if (!isEmpty(urlStr)) {
    const url = new URL(urlStr);
    const hostName = url.host.replace("www.", "");

    const parsed = psl.parse(hostName);
    const {domain, subdomain} = parsed;

    if (!isEmpty(allowedDomainsArray)) {
      const mappedAllowedDomainsArray = customMap(
        allowedDomainsArray,
        (eachItem) => customToLower(eachItem)
      );
      if (!isEmpty(subdomain)) {
        if (
          customIndexOf(
            mappedAllowedDomainsArray,
            customToLower(`${subdomain}.${domain}`)
          ) !== -1 ||
          customIndexOf(
            mappedAllowedDomainsArray,
            customToLower(`*.${domain}`)
          ) !== -1
        ) {
          isDomainAllowed = true;
        }
      } else if (
        customIndexOf(mappedAllowedDomainsArray, customToLower(domain)) !== -1
      ) {
        isDomainAllowed = true;
      }
    }
  }
  return isDomainAllowed;
};
export const whitelistedURL = [
  {
    ID: 1,
    OrgEntityID: "",
    Domain: "*.azurewebsites.net",
    IsActive: true,
    CreatedBy: "Rahul",
    CreatedTS: "2022-01-07T07:03:30.333",
    UpdatedBy: "akash.anil.mishra",
    UpdatedTS: "2022-02-16T02:49:50.917",
    OrgEntityName: "All",
  },
  {
    ID: 3,
    OrgEntityID: "",
    Domain: "*.tableau.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.34",
    UpdatedBy: "balmiki",
    UpdatedTS: "2022-01-07T07:03:30.34",
    OrgEntityName: "All",
  },
  {
    ID: 4,
    OrgEntityID: "",
    Domain: "analytics.google.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.343",
    UpdatedBy: "balmiki",
    UpdatedTS: "2022-01-07T07:03:30.343",
    OrgEntityName: "All",
  },
  {
    ID: 5,
    OrgEntityID: "",
    Domain: "*.adobe.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.343",
    UpdatedBy: "balmiki",
    UpdatedTS: "2022-01-07T07:03:30.343",
    OrgEntityName: "All",
  },
  {
    ID: 6,
    OrgEntityID: "",
    Domain: "*.accentureanalytics.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.347",
    UpdatedBy: "balmiki",
    UpdatedTS: "2022-01-07T07:03:30.347",
    OrgEntityName: "All",
  },
  {
    ID: 7,
    OrgEntityID: "",
    Domain: "*.accenture.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.347",
    UpdatedBy: "sumit.b.shekhar",
    UpdatedTS: "2022-02-16T04:14:51.69",
    OrgEntityName: "All",
  },
  {
    ID: 8,
    OrgEntityID: "",
    Domain: "accenture.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.347",
    UpdatedBy: "sumit.b.shekhar",
    UpdatedTS: "2022-02-16T04:14:51.69",
    OrgEntityName: "All",
  },
  {
    ID: 9,
    OrgEntityID: "",
    Domain: "*.service-now.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.347",
    UpdatedBy: "balmiki",
    UpdatedTS: "2022-01-07T07:03:30.347",
    OrgEntityName: "All",
  },
  {
    ID: 10,
    OrgEntityID: "",
    Domain: "app.powerbi.com",
    IsActive: true,
    CreatedBy: "Rahul",
    CreatedTS: "2022-01-07T07:03:30.35",
    UpdatedBy: "Rahul",
    UpdatedTS: "2022-01-07T07:03:30.35",
    OrgEntityName: "All",
  },
  {
    ID: 2,
    OrgEntityID: "",
    Domain: "apps.powerapps.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.333",
    UpdatedBy: "sumit.b.shekhar",
    UpdatedTS: "2022-02-16T02:49:50.917",
    OrgEntityName: "All",
  },
  {
    ID: 11,
    OrgEntityID: "",
    Domain: "*.sharepoint.com",
    IsActive: true,
    CreatedBy: "Balmiki",
    CreatedTS: "2022-01-07T07:03:30.35",
    UpdatedBy: "balmiki",
    UpdatedTS: "2022-01-07T07:03:30.35",
    OrgEntityName: "All",
  },
  {
    ID: 12,
    OrgEntityID: "",
    Domain: "*.invisionapp.com",
    IsActive: true,
    CreatedBy: "Rahul",
    CreatedTS: "2022-01-07T07:03:30.35",
    UpdatedBy: "Rahul",
    UpdatedTS: "2022-01-07T07:03:30.35",
    OrgEntityName: "All",
  },
  {
    ID: 50,
    OrgEntityID: "",
    Domain: "abc.acosta.com",
    IsActive: true,
    CreatedBy: "vishnu.sowdepally",
    CreatedTS: "2022-02-15T02:45:28.42",
    UpdatedBy: "vishnu.sowdepally",
    UpdatedTS: "2022-02-15T02:53:00.093",
    OrgEntityName: "All",
  },
  {
    ID: 52,
    OrgEntityID: "",
    Domain: "*.new.com",
    IsActive: true,
    CreatedBy: "vishnu.sowdepally",
    CreatedTS: "2022-02-15T02:54:03.727",
    UpdatedBy: "vishnu.sowdepally",
    UpdatedTS: "2022-02-15T02:54:03.727",
    OrgEntityName: "All",
  },
  {
    ID: 54,
    OrgEntityID: "",
    Domain: "oki.new.com",
    IsActive: true,
    CreatedBy: "akash.anil.mishra",
    CreatedTS: "2022-02-15T06:10:23.863",
    UpdatedBy: "akash.anil.mishra",
    UpdatedTS: "2022-02-15T06:10:23.863",
    OrgEntityName: "All",
  },
  {
    ID: 56,
    OrgEntityID: "",
    Domain: "*.microsoft.com",
    IsActive: true,
    CreatedBy: "vishnu.sowdepally",
    CreatedTS: "2022-02-16T03:51:08.547",
    UpdatedBy: "vishnu.sowdepally",
    UpdatedTS: "2022-02-16T03:51:08.547",
    OrgEntityName: "All",
  },
  {
    ID: 57,
    OrgEntityID: "",
    Domain: "*.office.com",
    IsActive: true,
    CreatedBy: "mdehteshamul.haque",
    CreatedTS: "2022-02-16T03:59:01.92",
    UpdatedBy: "mdehteshamul.haque",
    UpdatedTS: "2022-02-16T03:59:17.813",
    OrgEntityName: "All",
  },
  {
    ID: 59,
    OrgEntityID: "",
    Domain: "qwe.qwe.com",
    IsActive: true,
    CreatedBy: "akash.anil.mishra",
    CreatedTS: "2022-02-17T06:06:42.467",
    UpdatedBy: "akash.anil.mishra",
    UpdatedTS: "2022-02-17T06:06:42.467",
    OrgEntityName: "All",
  },
  {
    ID: 60,
    OrgEntityID: "",
    Domain: "*.newnew.com",
    IsActive: true,
    CreatedBy: "vishnu.sowdepally",
    CreatedTS: "2022-02-17T06:12:28.67",
    UpdatedBy: "vishnu.sowdepally",
    UpdatedTS: "2022-02-17T06:12:28.67",
    OrgEntityName: "All",
  },
  {
    ID: 61,
    OrgEntityID: "",
    Domain: "flipkart.com",
    IsActive: true,
    CreatedBy: "vishnu.sowdepally",
    CreatedTS: "2022-02-18T04:02:49.25",
    UpdatedBy: "vishnu.sowdepally",
    UpdatedTS: "2022-02-18T04:02:49.25",
    OrgEntityName: "All",
  },
];


export const PAGE_ELEMENTS = {
  OVERVIEW: "Overview",
  KEY_UPDATES: "Key Updates",
  CLIENT_STORY_OVERVIEW_KEY_MILESTONES: "Client Story Overview & Key Milestones",
  CLIENT_STORY_BEFORE_AFTER_MIND_MAP: "Client Story Before After Mind Map",
  CLIENT_STORY_BOI_MIND_MAP: "Client Story BOI Mind Map",
  INTELLIGENT_JOURNEY_DEPLOYED_INITIATIVES: "Intelligent Journey Deployed Initiatives",
  FEATURED_APPS: "Featured Apps",
  HIGHLIGHTS_FOR_YOU: "Highlights For You",
  FUNCTIONS_IN_DELIVERY: "Functions In Delivery",
  BUSINESS_OUTCOME: "Business Outcomes",
  REFERENCES: "References",
  INTELLIGENT_OPERATIONS_JOURNEY: "Intelligent Operations Journey",
  SUMMARY_DASHBOARD: "Summary Dashboard",
  INITIATIVE_TIMELINES: "Initiative Timelines",
  PMM: "PMM",
}

export const getIsPageElementEnabled = (...pageElements) => {
  let pageElementsState;
  if(pageElements[0] === PAGE_ELEMENTS.OVERVIEW){
    pageElementsState = sessionStorage.getItem("OVPageElements");
  }
  else if(pageElements[0] === PAGE_ELEMENTS.FUNCTIONS_IN_DELIVERY){
    pageElementsState = sessionStorage.getItem("FIDPageElements");
  }
  else {
    return true;
  }

  const pageElementsArray = JSON.parse(pageElementsState);

  if(!pageElementsArray){
    return true;
  }

  if (pageElements.length === 2) {
    return pageElementsArray.find((element) => element.key === pageElements[1])
      ?.value;
  }
  else if (pageElements.length === 3) {
    return pageElementsArray
      .find((element) => element.key === pageElements[1])
      ?.childElements.find(
        (childElement) => childElement.key === pageElements[2]
      )?.value;
  }
  else {
    return true;
  }
}

export const getFIDLandingPage = () => {
  if (
    getIsPageElementEnabled(
      PAGE_ELEMENTS.FUNCTIONS_IN_DELIVERY,
      PAGE_ELEMENTS.FUNCTIONS_IN_DELIVERY
    )
  ) {
    return "/FunctionsInDelivery";
  }

  if (
    getIsPageElementEnabled(
      PAGE_ELEMENTS.FUNCTIONS_IN_DELIVERY,
      PAGE_ELEMENTS.BUSINESS_OUTCOME
    )
  ) {
    return "/FunctionsInDelivery/BusinessOutcomes";
  }

  if (
    getIsPageElementEnabled(
      PAGE_ELEMENTS.FUNCTIONS_IN_DELIVERY,
      PAGE_ELEMENTS.INTELLIGENT_OPERATIONS_JOURNEY
    )
  ) {
    return "/FunctionsInDelivery/IntelligentOperationsJourney";
  }

  return "/FunctionsInDelivery";
};

export const findApprovedAliasName = (data, offeringName) => {
  let result = null;

  function searchOfferings(offerings) {
    for (const offering of offerings) {
      if (offering.offeringName === offeringName) {
        result = offering.approvedAliasName;
        return;
      }
      if (offering.childOfferings && offering.childOfferings.length > 0) {
        searchOfferings(offering.childOfferings);
        if (result) return;
      }
    }
  }

  searchOfferings(data ?? []);
  return result;
}