import React, { Component, Suspense } from "react";
import { HashRouter as Router } from "react-router-dom";
import { Preloader } from "./Preloader";
import "./App.css";
import * as Constants from "./Common";
import synopslogo from "./Images/synops-white-logo.svg";
import LockIcon from "./Images/lock-icon.svg";
import AccentureWhiteLogo from "./Images/Accenture-logowhite.svg";
import axiosInstance from "./Shared/Interceptor";
// import { loginRequest } from './Config/AdalConfig';
// import { loginRequest } from "../authConfig";
// import { msalInstance } from "./index";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import jwt_decode from "jwt-decode";
import ScrollToTop from "./ScrollToTop";
import { profilePicloginRequest, loginRequestPowerBi } from "./Config/AdalConfig";
import { msalInstance } from "./index";
import { SisenseProvider } from "./Shared/SisenseContext";

const Main = React.lazy(() => import("./Main"));

window.idleTimer = null;
window.currentTrackingModule = null;

class App extends Component {
  constructor() {
    super();
    this.state = {
      userId: 0,
      userAccess: false,
      isLoggedIn:false,
      userClients: [],
      languageCode:navigator.language || navigator.userLanguage,
      isActive: false,
      configLoc:
        window.location.hash.indexOf("Administration") > -1 ? true : false,
      location:
        window.location.hash.indexOf("Configuration") > -1 ||
        window.location.hash.indexOf("Administration") > -1
          ? true
          : false,
        humanTokenFlag: false,
          sisenseScript: {app: null, error: '', fetching: false, hasSisenseAccess: true}
    };
  }

  opriaURL = Constants.OPRIA_URL;

  sisenseIsLoaded() {
    return ((typeof window.Sisense !== "undefined") && (typeof window.prism !== "undefined"));
  }

  guid() {
    const S4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
      return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
  }

  loadSisense = () => {
    const isLoaded = this.sisenseIsLoaded();
    if (isLoaded) {
        const Sisense = window.Sisense;
        Sisense.connect(this.opriaURL, true).then(app => {
          this.setState({sisenseScript:{app, error: '', fetching: false, hasSisenseAccess: true}});
        }).catch((error) => {
          this.setState({sesenseScript:{app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true}});
        })
    }
}

  handleSisenseError = () => {
    this.setState({sisenseScript:{app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true}});
  }

  connect = () => {
    if (!this.sisenseIsLoaded()) {
        fetch(`${this.opriaURL}js/sisense.js`)
        .then((response) => {
            if (response.status === 200) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src =`${this.opriaURL}js/sisense.js?v=${this.guid()}`
                script.async = true;
                script.onload = this.loadSisense;
                script.onerror = this.handleSisenseError;
                document.body.appendChild(script);
              } else {
                this.setState({sisenseScript:{app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true}});
              }
        }).catch((error) => {
            this.setState({sisenseScript:{app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true}});
        })
    }
}

  UNSAFE_componentWillMount() {
    this.login();
  }
  componentDidUpdate() {
    if (this.state.isLoggedIn)
    {
      Constants.userTracker(Constants.TRACKING_MODULES.LOGIN, false);

    }
  }
  sendProps(details) {
    this.setState({ details });
  }

  login() {
  
    // if (sessionStorage.getItem("adal.idtoken") != undefined) {
    //   var token = sessionStorage.getItem("adal.idtoken");
    //   var tokendetails = JSON.parse(
    //     window.atob(
    //       token
    //         .split(".")[1]
    //         .replace("-", "+")
    //         .replace("_", "/")
    //     )
    //   );
    //   var enterpriseId = tokendetails["unique_name"].split("@")[0];
    //   sessionStorage.setItem("EnterpriseID", enterpriseId);
    //   var dateObj = Date.now() + 86400000 * 3;
    //   var n = dateObj;
    //   Constants.setCookie("tokenExpiresTime", n, 10);
    //   Constants.setCookie(
    //     "authToken",
    //     sessionStorage.getItem("adal.idtoken"),
    //     1
    //   );
    //   if (sessionStorage.getItem("userId") == null) {
    //     this.authorizeUser();
    //   } else {
    //     this.setState({
    //       userId: sessionStorage.getItem("userId"),
    //       userClients: sessionStorage.getItem("userClients"),
    //       userAccess: true,
    //     });
    //   }
    // }
    // else
    // {

    //Uncomment this to run in local and change token 
    // sessionStorage.setItem(
    //   "accessToken",
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI0M2UzYTBiMC1iMDllLTQ5ZjMtOGY1MC04NjVkMWRhZDc4NzUiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIvIiwiaWF0IjoxNjU2Njc4MTYyLCJuYmYiOjE2NTY2NzgxNjIsImV4cCI6MTY1NjY4MjkyOSwiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhUQUFBQVVEd0pwSDF5RDE1M21QNXJBQjd0M3oyNllsZmtDVFlPTmtwM1o3MFQwRjFQL20wamMyay9YWENLQW1HekhudEVpekNwbk0yYzZUVFRPTEdwdHFVbldzakk0QTdkUk05RUVnNktFN2dja0d3PSIsImFtciI6WyJwd2QiLCJtZmEiXSwiYXBwaWQiOiI0M2UzYTBiMC1iMDllLTQ5ZjMtOGY1MC04NjVkMWRhZDc4NzUiLCJhcHBpZGFjciI6IjAiLCJmYW1pbHlfbmFtZSI6IlBhbmRleSIsImdpdmVuX25hbWUiOiJTYXJnYW0iLCJpcGFkZHIiOiIxMjIuMTYyLjE0Ny4zMyIsIm5hbWUiOiJQYW5kZXksIFNhcmdhbSIsIm9pZCI6IjZkNjU5NDI5LWQ3YjUtNDIwMi04MDlhLTQwOWM2NGM5ZmI1NyIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS04NjE1Njc1MDEtNDEzMDI3MzIyLTE4MDE2NzQ1MzEtNTQyNDY1MSIsInJoIjoiMC5BU1lBRGgwaDgxc1N3MEtHMnpJckdhWmFJckNnNDBPZXNQTkpqMUNHWFIydGVIVW1BT0EuIiwic2NwIjoiVXNlci5SZWFkIHVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6ImpEd1Z3UVVJLTY4enl3cXp5NlZKOFNlMFpVVnZqalh3SktSQXU1NFBRcDgiLCJ0aWQiOiJmMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIiLCJ1bmlxdWVfbmFtZSI6InNhcmdhbS5wYW5kZXlAZHMuZGV2LmFjY2VudHVyZS5jb20iLCJ1cG4iOiJzYXJnYW0ucGFuZGV5QGRzLmRldi5hY2NlbnR1cmUuY29tIiwidXRpIjoicUQyWktsX1BNRWVndG1GUnhwa01BUSIsInZlciI6IjEuMCJ9.l8Q-xECgmLSePRxrwYFvo0cHlwUfQtMKAh5bDNmGk5A6KeNhlF23STAk9GgTnkoWEmIAi4uwzDFDznFoy6E7oNDPmlHOl-mGP02kUcThil4RpnNTJxYjUN_OovbJn3qACL_RloTaFZ0PqrLGTMrSOUGNbxA-huoYCDDcLfe40vx7lDKSYptpXhilC9SDVAJQbBX9pKBopXO__i_o0u06bJsm0E-LxNgioaVGTAXSJS-C333Nrmua3YTFQ51MbizjoD3Qn1Kx4Ee3WG_0kf9lT8iG-TzwEB6xksh4QOQKHYMPJ5ygMzB20F2cm7Tf7RERlHItGDZpsKgKVDOD4bU2TQ"
    //    );
      if (sessionStorage.getItem("userId") == null) {
        this.authorizeUser();
      }
      else
      {
         this.setState({
          userId: sessionStorage.getItem("userId"),
          userClients: sessionStorage.getItem("userClients"),
          userAccess: true,
         });
      }
    //}
  }

  HumanApiToken(){
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
     msalInstance
      .acquireTokenSilent({
        ...profilePicloginRequest,
        account: account,
      }).then((response)=>{
        sessionStorage.setItem("profilePicToken",response.accessToken);
        this.setState({humanTokenFlag:true});
      })
      .catch((err) => {
        console.log('Error getting popup token: ' + JSON.stringify(err));

      });
  }

  pbiToken(){
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    msalInstance
      .acquireTokenSilent({
        ...loginRequestPowerBi,
      }).then((response)=>{
        sessionStorage.setItem("pbiToken",response.accessToken);
      }
      )
      .catch((err) => {
        console.log('Error getting popup token: ' + JSON.stringify(err));
      });
  }

componentDidMount() {
    this.getBrowserLanguage();
    const hasSisenseAccess = sessionStorage.getItem("hasSisenseAccess");
    if(hasSisenseAccess) {
      this.setState({
        sisenseScript: {
          app: null,
          error: "",
          fetching: false,
          hasSisenseAccess: hasSisenseAccess === "true",
        },
      });
      if(hasSisenseAccess === "true") {
        this.connect();
      }
    }
}
getBrowserLanguage(){
    // let obj={
    //  "en":"EN",
    //  "ja":"ja",
    //  "zh":"zh"
    //}
     var userLang = navigator.language || navigator.userLanguage; 
    //  let lang=userLang.toString().toLowerCase().includes("en")?"en":userLang.toString().toLowerCase().includes()
    //  let languageCode=obj[lang]!== undefined ? obj[lang] : "EN" // if language other than english or japanies bydefault english is selected
    this.setState({
      languageCode:userLang})
 }
   authorizeUser() {
    //   const account = msalInstance.getActiveAccount();
    //   if (!account) {
    //       throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    //   }
    //   const response = await msalInstance.acquireTokenSilent({
    //     ...loginRequest,
    //     account: account
    // }).catch(err => {
    //     //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
    //     //always check if the error is something that requires auth login interaction 
    //     if (err instanceof InteractionRequiredAuthError) {
    //         return msalInstance.acquireTokenPopup({
    //             ...loginRequest,
    //             account: msalInstance.getActiveAccount()
    //         }).catch(err => {
    //             console.log('Error getting popup token: ' + JSON.stringify(err))
    //         });
    //     }
    // });

    axiosInstance
      .post(
        `${Constants.BASEURL}UserAuthorization/user-auth?languageCode=${this.state.languageCode}`
      )
      .then((response) => {
       
        let fetching = false;
        if(response.data.hasSisenseAccess) {
          this.connect();
          fetching = true;
        }
        else {
          fetching = false;
        }

                this.setState(
                    {
                        userId: response.data.userId,
                        userClients: response.data.userClients,
                        userAccess: true,
                        isLoggedIn:true,
                        sisenseScript: {app: null, error: '', fetching, hasSisenseAccess: response.data.hasSisenseAccess}
                    },
                    sessionStorage.setItem("userId", response.data.userId),
                    sessionStorage.setItem("userName", response.data.userName),
                    sessionStorage.setItem("clientId", response.data.userClients[0].clientId),
                    sessionStorage.setItem("clientName", response.data.userClients[0].orgEntityName),
                    sessionStorage.setItem("isClientUser", response.data.isClientUser),
                    sessionStorage.setItem("isClientAdmin", response.data.isClientAdmin),
                    sessionStorage.setItem("isAdmin", response.data.isAdmin),
                    sessionStorage.setItem("userLoginId", response.data.userLoginId),
                    sessionStorage.setItem("landingPage", response.data.landingPage == "4" ? "Administration" : (response.data.landingPage == "3") ? "Configuration" : (response.data.landingPage == "2") ? "Functions in Delivery" : "Overview"),
                    sessionStorage.setItem("headerLandingPage", response.data.landingPage == "2" ? "Functions in Delivery" : "Overview"),
                    sessionStorage.setItem("languageCode", response.data.languageCode),
                    sessionStorage.setItem("isLoggedIn", true),
                    sessionStorage.setItem("userClients", JSON.stringify(response.data.userClients)),
                    sessionStorage.setItem("clientLogo", response.data.userClients[0].orgEntityImage == null || response.data.userClients[0].orgEntityImage == "" ? Constants.StaticClientLogoURL : Constants.ClientLogoURL + response.data.userClients[0].orgEntityImage),
                    sessionStorage.setItem("theme", response.data.intValue),
                    sessionStorage.setItem("mycockpit", response.data.myCockpitIntValue),
                    sessionStorage.setItem("guidedtourActive",false),
                    sessionStorage.setItem("isGuestUser", response.data.isGuestUser),
                    sessionStorage.setItem("GTour","false"),
                    sessionStorage.setItem("isFirstTimeLogin", true),
                    sessionStorage.setItem("hasSisenseAccess", response.data.hasSisenseAccess),
                    sessionStorage.setItem("dontShowAnnouncement", response.data.donotshowpopup)

                )
                if (response.data.userClients != null && response.data.userClients.length > 1) {
                    window.location = '/#/landingpage'
                }

                else if (response.data.isAdmin === true) {
                    sessionStorage.setItem("UserCat", "Administration");
                    window.location = '/#/Administration/Administration';
                }
                else if (response.data.isClientAdmin === true) {
                    sessionStorage.setItem("UserCat", "Configuration");
                    window.location = '/#/Configuration/Configuration';
                }
                else if (response.data.isClientUser === true && response.data.landingPage == "2") {
                    window.location = '/#/FunctionsInDelivery';
                }
                else if (response.data.isClientUser === true) {
                    window.location = '/#/Overview';
                }

                else if (response.data.landingPage == "2") {
                    window.location = '/#/FunctionsInDelivery';
                }
                else {
                    window.location = '/#/Overview';
                }
             if(!response.data.isClientUser){
                  this.HumanApiToken();
                  this.pbiToken();
                }
            })
            .catch(() => {
                this.setState(
                    {
                        userId: null,
                        userClients: null,
                        userAccess: false
                    })
                sessionStorage.setItem("status", 401)
            });
    }

  render() {
    return (
      <>
        <div id="sisenseApp"></div>
        {(
          // sessionStorage.getItem("adal.idtoken") != undefined &&
          // sessionStorage.getItem("adal.idtoken") != null &&
          this.state.userId == null) ||
        (sessionStorage.getItem("isClientUser") === "true" &&
          this.state.location === true) ||
        (sessionStorage.getItem("isClientAdmin") === "true" &&
          this.state.configLoc === true) ||
        (this.state.location === true &&
          sessionStorage.getItem("isAdmin") === "false" &&
          sessionStorage.getItem("isClientAdmin") === "false" &&
          sessionStorage.getItem("isClientUser") === "false") ? (
          <div className="unauthorized-screen">
            <div className="unauthorized-block">
              <img src={synopslogo} className="mb-5" />
              <div className="deniedaccess-heading mb-3">
                <img src={LockIcon} className="mr-3" />
                <h4 className="bodytext24-regular-white">Access Denied</h4>
              </div>

              <p className="bodytext16-regular-white mb-4">
                You don’t have the necessary user privileges to view the page
                you are attempting to access.{" "}
              </p>
              <img src={AccentureWhiteLogo} className="mb-1" width="120px" />
            </div>
          </div>
        ) : this.state.userId > 0 &&
          this.state.userClients != null &&
          this.state.userClients.length > 0 ? (
          <Router>
            <ScrollToTop />
            <Suspense fallback={<Preloader />}>
              <SisenseProvider value={{sisense: this.state.sisenseScript}}>
              <Main humanTokenFlag={this.state.humanTokenFlag}/>
              </SisenseProvider>
            </Suspense>
          </Router>
        ) : this.state.userAccess ? (
          <div className="unauthorized-screen">
            <div className="unauthorized-block">
              <img src={synopslogo} className="mb-5" />
              <div className="deniedaccess-heading mb-3">
                <img src={LockIcon} className="mr-3" />
                <h4 className="bodytext24-regular-white">Access Denied</h4>
              </div>

              <p className="bodytext16-regular-white mb-4">
                You don’t have the necessary user privileges to view the page
                you are attempting to access.{" "}
              </p>
              <img src={AccentureWhiteLogo} className="mb-1" width="120px" />
            </div>
          </div>
        ) : (
          <Preloader />
        )}
      </>
    );
  }
}

export default App;
